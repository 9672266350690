
  #machine {
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .reel {
    width: 90px;
    height: 100%;
    display: inline-block;
    position: relative;
    font-size: 35px;
    line-height: 50px;
  }
  
  .quota, .quota div, .quota:before, .quota:after {
    width: 100%;
    height: 50px;
    position: absolute;
  }
  
  .quota {
    top: 33px;
    pointer-events: none;
    -webkit-perspective: 500px;
    perspective: 500px;
  }
  
  .reel:first-child .quota {
    -webkit-perspective-origin: 120px 50%;
    perspective-origin: 120px 50%;
  }
  
  .reel:last-child .quota {
    -webkit-perspective-origin: -50px 50%;
    perspective-origin: -50px 50%;
  }
  
  .quota div, .quota:before, .quota:after {
    -webkit-transform-origin: inherit;
    transform-origin: inherit;
  }
  
  .quota div {
    left: 0;
    /* background: url(http://ataredo.com/external/image/elect.png); */
    background-color: #d9d9d9;
    background-size: 100% 100%;
    border-left: 1px solid #2e363e;
    border-right: 1px solid #2e363e;
    outline: 1px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: auto;
    text-align: center;
    color: #000;
    font-size: 25px;
  }
  
  .rotate div {
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: inherit;
    transition-duration: inherit;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  
  .quota:before, .quota:after {
    content: "";
    left: 0;
    z-index: 1;
  }
  
  .quota:before {
    background: -webkit-linear-gradient(rgba(50,50,50,0.7) 10%, transparent);
    background: linear-gradient(rgba(50,50,50,0.7) 10%, transparent);
    -webkit-transform: rotateX(36deg);
    transform: rotateX(36deg);
  }
  
  .quota:after {
    background: -webkit-linear-gradient(transparent, rgba(50,50,50,0.7) 70%);
    background: linear-gradient(transparent, rgba(50,50,50,0.7) 70%);
    -webkit-transform: rotateX(-36deg);
    transform: rotateX(-36deg);
  }
  
  /* .reel:first-child .quota:before {
    -webkit-clip-path: polygon(3% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
    clip-path: polygon(3% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
  }
  
  .reel:first-child .quota:after {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%, 0 70%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%, 0 70%);
  }
  
  .reel:last-child .quota:before {
    -webkit-clip-path: polygon(0 0, 97% 0, 100% 30%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 97% 0, 100% 30%, 100% 100%, 0 100%);
  }
  
  .reel:last-child .quota:after {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 97% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 70%, 97% 100%, 0 100%);
  } */